import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import defaultImg from "../images/defaultImg.jpg";
import SideBar from "../components/common/SideBar";
import header_test from "../images/header_test.png";
// import dreyahgymImg from "../images/dreyahgymImg.jpg";

const AboutEl = styled.div`
  .container {
    display: flex;
    padding: 4% 7%;

    .main-content-wrapper {
      padding-right: 5%;
      width: 75%;

      .h4-text {
        text-align: center;
      }

      .entry-content {
        .image-wrapper {
          display: flex;
          width: 50%;
          img {
            height: 350px;
            background: cover;
            width: 100%;
            object-fit: cover;
            padding: 2%;
          }
        }

        .entry-content-text {
          text-align: justify;
          font-size: 0.9rem;
          font-family: "karla", open sans;
        }
        .entry-content-title {
          color: #8883d4;
        }
        .entry-content-subtitle {
          margin-bottom: 0.45rem;
          font-size: inherit;
          font-style: italic;
        }
      }
    }
  }

  /* media queries start here */

  @media screen and (max-width: 1200px) {
    .container {
      flex-direction: Column;

      .main-content-wrapper {
        width: 100%;
        padding-right: 0px;
      }
    }
  }

  @media screen and (max-width: 623px) {
    .container .main-content-wrapper .entry-content .image-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
`;

const About = () => {
  return (
    <Layout heroText={"About"} headerImage={header_test}>
      <AboutEl>
        <div className="container">
          <div className="main-content-wrapper">
            <h4 className="h4-text">
              {
                "Every Fit Girl inspires wellness, realness & fearless motivation in your daily life!"
              }
            </h4>
            <div className="entry-content">
              <p className="paragraph-text">
                {
                  "Every Fit Girl is a digital fitness and lifestyle brand focused on making positivity louder. Here you'll find the best resources you need to live an authentic, fit and healthy lifestyle, while having as much fun as possible."
                }
              </p>

              <p className="paragraph-text">
                {
                  "Here you'll find content that helps you become a better and healthier version of yourself while navigating through life, because your journey isn’t about perfection, it’s about moving forward, sometimes backward, and making the most out of every moment in between."
                }
              </p>

              {/*               
              <p className="paragraph-text">
                {
                  "I'm Dreyah, a fitness entrepreneur, trainer, dance instructor and most recently a software developer. I created Dreyah Fit to provide women with the best resources they need to live an authentic, fit and healthy lifestyle, while having as much fun as possible."
                }
              </p>

              <p className="paragraph-text">
                {
                  "I’ve been a fitness trainer for over 8 years, and I've helped thousands of women from different parts of the world achieve their fitness goals."
                }
              </p>
              <p className="paragraph-text">
                {
                  "I grew up as a dancer and athlete and nowadays I host dance fitness events, train clients face to face, around the world over Skype, teach classes for incredible brands and I also get to meet some of you at my events!"
                }
              </p> */}
              {/* <div className="image-wrapper">
                <img alt="dreyahgymImg" src={dreyahgymImg} />
                <img alt="dreyahgymImg" src={dreyahgymImg} />
              </div> */}

              <p className="paragraph-text">
                {
                  "Through Every Fit Girl, we hope to inspire wellness, realness, and fearless motivation in your daily life and to remind every girl of your own power and worth."
                }
              </p>

              {/* <p className="paragraph-text">
                {
                  "I created Dreyah Fit to share my ever-evolving fitness journey, while giving women the inspiration to take action in their own lives, because fitness isn’t about perfection, it’s about moving forward, sometimes backward, and making the most of every moment in between. Through Dreyah Fit, I hope to inspire wellness, realness, and fearless motivation in your daily life. I want you to make yourself proud. I am here to remind you of your own power and worth."
                }
              </p> */}

              {/* <p className="paragraph-text">
                {
                  " I strongly believe in a healthy kind of hustle that includes full belly laughs, making mistakes, and whole-heartedly loving what you do."
                }
              </p> */}

              {/* <p className="paragraph-text">
                {
                  "Recently, I became a software developer after re-discovering my love and passion for technology and creating products and experiences that ultimately change lives. With that knowledge I intend to give you the very best experience here at dreyahfit.com. Thank you for being here. Stay awhile :)"
                }
              </p> */}

              <h4 className="h4-text entry-content-title">{"FAQ's"}</h4>
              {/* <h3 className="entry-content-subtitle">
                {"How did Dreyah Fit start?"}
              </h3>
              <p className="paragraph-text">
                {
                  "Sometime in 2015, Dreyah Fit was a platform on instagram I started to simply document my journey through health and fitness, but has slowly grown into an amazing community of women I love and cherish. Luckily for me, my incredible subscribers and online community made me realise just how much I have always loved fitness, it was then that I decided to take the plunge and create Dreyah Fit into a brand for bold, courageuos and fearless women."
                }
              </p> */}

              <h3 className="entry-content-subtitle">
                {"How can I lose weight?"}
              </h3>
              <p className="paragraph-text">
                {
                  "There is no big secret to weight-loss, it’s simply a lifestyle change. Diets don’t work because they’re temporary. They have a start and end date, which means they're not sustainable long term. Small changes make big differences, you don’t always have to remove things from your life to lose weight, sometimes it’s about swapping it for a healthier alternative or adding a new routine to your day. Anyone can succeed, if they really want it."
                }
              </p>

              <h3 className="entry-content-subtitle">
                {"How can I gain-weight?"}
              </h3>
              <p className="paragraph-text">
                {
                  "There are also no big secrets to healthy weight-gain, it’s simply a lifestyle change. To gain healthy weight, you would need to consume more calories than you regularly burn everyday. It's also important to keep your meals as healthy and clean as possible while doing so. Always remember, It's quality over quantity. To get the most out of your gains, try to keep a consistent lifting or workout schedule that challenges you every time."
                }
              </p>
            </div>
          </div>
          <SideBar />
        </div>
      </AboutEl>
    </Layout>
  );
};

export default About;
